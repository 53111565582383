body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}
.loginf-form {
  max-width: 400px;
  position: relative;
  padding-block-start: 77px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loginf-form {
    display: inline-flexbox;
    align-items: center;
}
.container1 {
  
  align-items: center;
  height: 100%;

  position: fixed;
  background-size: cover;
  background-position: center;

}

.container{
  margin: 0 auto;
  max-width: 900;
}

.error-notice{
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #e07c7c;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8d6d6;
}

.error-notice button {
  /*width: 20px;
  height: 20px;*/
  border-radius: 50%;
  background-color: #df4343;
  color: #ffffff;
}

.form {
  margin: 0 auto;
  max-width: 900;
  text-align: center;
}
 .form input{
  min-width: 700px;
}
.form input[type="submit"]{
  background-color: #2196F3;
    border: 1px solid #2196F3;
    color: #ffffff;
    border-radius: 5px;
    height: 40px;
    width: 100px;
    margin-top: 15px;
}

.button-border{
  border-right: 1px solid white !important;
    border-radius: 0px !important;
}

.login {
  margin-left: auto;
  margin-right: auto;
  /*padding: 168px;*/
  /*background-image: url(./img/img-stone.jpg);*/
  align-items: center;
  /*height: 1365px;*/
  background-size: cover;
  background-position: center;

}

.makeStyles-paper-449 {
  color: rgb(0, 0, 0) !important;
  padding: 16px;
  text-align: center;
}

.shadow {
  box-shadow: 5px 5px 1px -1px rgb(0, 0, 0), 0px 1px 1px 0px rgb(0, 0, 0), 5px 0px 0px 0px rgb(0, 0, 0);
}

.content{
  min-height: 578px;
}

@media (min-width: 768px) {
    
    .container{
      margin: 0 auto;
      max-width: 700px;
    }
}
