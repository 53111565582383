.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  display: block;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  /* top layer */
  z-index: 9999;
}

.overlay {
  display: none;
  position: fixed;
  /* full screen */
  width: 100vw;
  height: 100vh;
  /* transparent black */
  background: rgba(0, 0, 0, 0.7);
  /* middle layer, i.e. appears below the sidebar */
  z-index: 998;
  opacity: 0;
  /* animate the transition */
  transition: all 0.5s ease-in-out;
}
/* display .overlay when it has the .active class */
.overlay.active {
  display: block;
  opacity: 1;
}

#dismiss {
  width: 35px;
  height: 35px;
  position: absolute;
  /* top right corner of the sidebar */
  top: 10px;
  right: 10px;
}


.makeStyles-paper-449 {
  color: rgb(0, 0, 0) !important;
  padding: 16px;
  text-align: center;
}

shadow {
  box-shadow: 5px 5px 1px -1px rgb(0, 0, 0), 0px 1px 1px 0px rgb(0, 0, 0), 5px 0px 0px 0px rgb(0, 0, 0);
}

.navCheckbox {
  cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: middle;
    /* -webkit-tap-highlight-color: transparent; */
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.navCheckbox1 {
  cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: -11px;
    margin-right: 16px;
    vertical-align: middle;
    /* -webkit-tap-highlight-color: transparent; */
    margin-bottom: 1.3rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}